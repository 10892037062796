import { useState } from 'react';
import { useBookStore } from '../store/bookStore';
import { generatePDF } from '../utils/export/pdfExport';
import { generateEPUB } from '../utils/export/epubExport';

export function useExport() {
  const [isExporting, setIsExporting] = useState(false);
  const { currentProject } = useBookStore();

  const exportToPDF = async () => {
    if (!currentProject) return;
    
    try {
      setIsExporting(true);
      await generatePDF(currentProject);
    } catch (error) {
      console.error('Erreur lors de l\'export PDF:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const exportToEPUB = async () => {
    if (!currentProject) return;
    
    try {
      setIsExporting(true);
      await generateEPUB(currentProject);
    } catch (error) {
      console.error('Erreur lors de l\'export EPUB:', error);
    } finally {
      setIsExporting(false);
    }
  };

  return {
    exportToPDF,
    exportToEPUB,
    isExporting
  };
}
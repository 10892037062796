import React from 'react';
import { PagePreview } from './PagePreview';
import { ParsedBlock } from '../../types/content';

interface PageGridProps {
  pages: ParsedBlock[][];
  zoom: number;
  pagesPerRow: number;
  startPage: number;
  endPage: number;
}

export const PageGrid: React.FC<PageGridProps> = ({
  pages,
  zoom,
  pagesPerRow,
  startPage,
  endPage
}) => {
  return (
    <div 
      className="grid gap-8"
      style={{
        gridTemplateColumns: `repeat(${pagesPerRow}, minmax(0, 1fr))`
      }}
    >
      {pages.slice(startPage, endPage + 1).map((content, index) => (
        <div 
          key={startPage + index}
          style={{ transform: `scale(${zoom})` }}
          className="transform-origin-center transition-transform duration-200"
        >
          <PagePreview
            content={content}
            pageNumber={startPage + index + 1}
          />
        </div>
      ))}
    </div>
  );
};
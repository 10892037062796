import React from 'react';
import { useBookStore } from '../../store/bookStore';
import { calculatePageDimensions } from '../../utils/pageCalculations';
import { ContentBlock } from './ContentBlock';
import { StyleType } from '../../types/styles';
import { getPageMargins } from '../../utils/pageMargins';

interface Block {
  type: StyleType;
  content: string;
  isImage?: boolean;
  layout?: 'normal' | 'fullWidth' | 'fullPage';
}

interface PagePreviewProps {
  content: Block[];
  pageNumber: number;
}

export const PagePreview: React.FC<PagePreviewProps> = ({ content, pageNumber }) => {
  const { currentProject } = useBookStore();
  const dimensions = calculatePageDimensions(currentProject?.size);
  const styles = currentProject?.styles;
  const isEvenPage = pageNumber % 2 === 0;
  const margins = getPageMargins(dimensions, isEvenPage);

  let currentY = margins.top * 96;

  return (
    <div 
      className="bg-white shadow-lg mx-auto relative"
      style={{
        width: `${dimensions.width * 96}px`,
        height: `${dimensions.height * 96}px`,
        overflow: 'hidden'
      }}
    >
      {content.map((block, index) => {
        const blockMarginTop = block.type.startsWith('heading') ? 48 : 24;
        const previousBlock = index > 0 ? content[index - 1] : null;
        
        if (previousBlock) {
          currentY += blockMarginTop;
        }

        const element = (
          <div
            key={index}
            className="absolute w-full"
            style={{
              top: currentY,
              left: margins.left * 96,
              right: margins.right * 96,
              ...(block.layout === 'fullPage' && {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              })
            }}
          >
            <ContentBlock
              content={block.content}
              type={block.type}
              style={styles?.[block.type]}
              isImage={block.isImage}
              layout={block.layout}
              isEvenPage={isEvenPage}
            />
          </div>
        );

        // Mettre à jour la position Y pour le prochain bloc
        if (!block.layout || block.layout === 'normal') {
          currentY += block.isImage ? 200 : (block.type.startsWith('heading') ? 48 : 24);
        } else if (block.layout === 'fullWidth') {
          currentY += 300;
        }

        return element;
      })}
      
      {!content.some(block => block.layout === 'fullPage') && (
        <div className="absolute bottom-4 w-full text-center text-gray-400">
          {pageNumber}
        </div>
      )}
    </div>
  );
};
import React from 'react';
import { TextStyle, StyleType } from '../../types/styles';
import { DEFAULT_STYLES } from '../../constants/defaultStyles';
import { ImageDisplay } from '../common/ImageDisplay';
import { useBookStore } from '../../store/bookStore';
import { calculatePageDimensions } from '../../utils/pageCalculations';

interface ContentBlockProps {
  content: string;
  type: StyleType;
  style?: TextStyle;
  isImage?: boolean;
  isEvenPage: boolean;
  layout?: 'normal' | 'fullWidth' | 'fullPage';
}

export const ContentBlock: React.FC<ContentBlockProps> = ({ 
  content, 
  type,
  style,
  isImage,
  layout = 'normal'
}) => {
  const { currentProject } = useBookStore();
  const dimensions = calculatePageDimensions(currentProject?.size);

  if (isImage) {
    if (layout === 'fullPage') {
      return (
        <div 
          className="absolute inset-0 overflow-hidden" 
          style={{ 
            margin: dimensions.bleed ? `-${dimensions.bleed}in` : '0',
          }}
        >
          <ImageDisplay 
            src={content} 
            className="w-auto h-full"
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              height: '100%',
              width: 'auto',
              maxWidth: 'none'
            }}
          />
        </div>
      );
    }

    if (layout === 'fullWidth') {
      return (
        <div className="w-full">
          <ImageDisplay 
            src={content} 
            className="w-full h-auto"
          />
        </div>
      );
    }

    return <ImageDisplay src={content} className="max-w-full h-auto" />;
  }

  const blockStyle = style || DEFAULT_STYLES[type];

  const inlineStyle = React.useMemo(() => ({
    fontFamily: blockStyle.fontFamily,
    fontSize: `${blockStyle.fontSize}pt`,
    lineHeight: blockStyle.lineHeight,
    fontWeight: blockStyle.isBold ? 'bold' : 'normal',
    fontStyle: blockStyle.isItalic ? 'italic' : 'normal',
    textAlign: blockStyle.alignment,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '100%'
  }), [blockStyle]);

  return <div style={inlineStyle}>{content}</div>;
};
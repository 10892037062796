import { BookDimensions } from '../types/dimensions';

interface ImageDimensions {
  width: number;
  height: number;
  x: number;
  y: number;
}

export function calculateFullPageImageDimensions(
  imageWidth: number,
  imageHeight: number,
  pageDimensions: BookDimensions
): ImageDimensions {
  const pageHeight = pageDimensions.withBleed.height * 96; // Conversion en pixels
  const aspectRatio = imageWidth / imageHeight;
  
  // Calculer la hauteur en conservant le ratio
  const targetHeight = pageHeight;
  const targetWidth = targetHeight * aspectRatio;
  
  // Centrer horizontalement
  const x = -(targetWidth - (pageDimensions.withBleed.width * 96)) / 2;
  
  return {
    width: targetWidth,
    height: targetHeight,
    x,
    y: 0
  };
}

export function calculateFullWidthImageDimensions(
  imageWidth: number,
  imageHeight: number,
  pageDimensions: BookDimensions,
  margins: { left: number; right: number }
): ImageDimensions {
  const availableWidth = (pageDimensions.width - margins.left - margins.right) * 96;
  const aspectRatio = imageWidth / imageHeight;
  
  const targetWidth = availableWidth;
  const targetHeight = targetWidth / aspectRatio;
  
  return {
    width: targetWidth,
    height: targetHeight,
    x: margins.left * 96,
    y: 0
  };
}
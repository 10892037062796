import React from 'react';
import { ZoomIn, ZoomOut, Columns, Maximize2 } from 'lucide-react';

interface PreviewControlsProps {
  zoom: number;
  onZoomChange: (zoom: number) => void;
  pagesPerRow: number;
  onPagesPerRowChange: (pages: number) => void;
}

export const PreviewControls: React.FC<PreviewControlsProps> = ({
  zoom,
  onZoomChange,
  pagesPerRow,
  onPagesPerRowChange,
}) => {
  return (
    <div className="flex items-center justify-between mb-4 bg-white p-2 rounded-lg shadow-sm">
      <div className="flex items-center space-x-2">
        <button
          onClick={() => onZoomChange(Math.max(0.5, zoom - 0.1))}
          className="p-2 hover:bg-gray-100 rounded-lg"
          title="Réduire"
        >
          <ZoomOut className="w-5 h-5" />
        </button>
        <span className="text-sm text-gray-600 min-w-[4rem] text-center">
          {Math.round(zoom * 100)}%
        </span>
        <button
          onClick={() => onZoomChange(Math.min(2, zoom + 0.1))}
          className="p-2 hover:bg-gray-100 rounded-lg"
          title="Agrandir"
        >
          <ZoomIn className="w-5 h-5" />
        </button>
        <button
          onClick={() => onZoomChange(1)}
          className="p-2 hover:bg-gray-100 rounded-lg"
          title="Taille réelle"
        >
          <Maximize2 className="w-5 h-5" />
        </button>
      </div>

      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-600">Pages par ligne:</span>
        <button
          onClick={() => onPagesPerRowChange(1)}
          className={`p-2 rounded-lg ${
            pagesPerRow === 1 ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
          }`}
        >
          <Columns className="w-5 h-5" />
          <span className="sr-only">1 page</span>
        </button>
        <button
          onClick={() => onPagesPerRowChange(2)}
          className={`p-2 rounded-lg ${
            pagesPerRow === 2 ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
          }`}
        >
          <div className="flex">
            <Columns className="w-5 h-5" />
            <Columns className="w-5 h-5 -ml-2" />
          </div>
          <span className="sr-only">2 pages</span>
        </button>
      </div>
    </div>
  );
};
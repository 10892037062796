import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical } from 'lucide-react';
import { ParsedBlock } from '../../types/content';
import { PreviewContainer } from '../preview/PreviewContainer';

interface SortablePageProps {
  id: string;
  pageNumber: number;
  content: ParsedBlock[];
}

export const SortablePage: React.FC<SortablePageProps> = ({
  id,
  pageNumber,
  content
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="bg-white rounded-lg border border-gray-200 overflow-hidden"
    >
      <div className="flex items-center p-4 bg-gray-50 border-b">
        <div
          {...attributes}
          {...listeners}
          className="cursor-grab hover:bg-gray-100 p-2 rounded-lg"
        >
          <GripVertical className="w-5 h-5 text-gray-400" />
        </div>
        <span className="ml-4 text-sm font-medium text-gray-700">
          Page {pageNumber}
        </span>
      </div>
      <div className="p-4">
        <div className="transform scale-50 origin-top-left">
          <PreviewContainer pages={[content]} readOnly />
        </div>
      </div>
    </div>
  );
};
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Fonction utilitaire pour vérifier la connexion
export async function checkConnection(retries = 3): Promise<boolean> {
  for (let i = 0; i < retries; i++) {
    try {
      const { error } = await supabase.from('projects').select('count').limit(1);
      if (!error) return true;
      console.warn(`Tentative de connexion ${i + 1}/${retries} échouée`);
      await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
    } catch (error) {
      console.error('Erreur de connexion:', error);
      if (i === retries - 1) return false;
    }
  }
  return false;
}

// Fonction utilitaire pour les requêtes avec retry
export async function withRetry<T>(
  operation: () => Promise<T>,
  retries = 3
): Promise<T> {
  for (let i = 0; i < retries; i++) {
    try {
      return await operation();
    } catch (error) {
      if (i === retries - 1) throw error;
      console.warn(`Tentative ${i + 1}/${retries} échouée, nouvelle tentative...`);
      await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
    }
  }
  throw new Error('Toutes les tentatives ont échoué');
}
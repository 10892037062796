import React from 'react';
import { TextStyle, StyleType } from '../../types/styles';

interface PreviewTextProps {
  style: TextStyle;
  type: StyleType;
}

const PREVIEW_TEXTS: Record<StyleType, string> = {
  paragraph: 'Voici un exemple de texte pour le corps de texte.',
  heading1: 'Titre de niveau 1',
  heading2: 'Titre de niveau 2',
  heading3: 'Titre de niveau 3',
  blockquote: 'Une citation importante à mettre en valeur',
  strong: 'Texte en gras',
  emphasis: 'Texte en italique'
};

export const PreviewText: React.FC<PreviewTextProps> = ({ style, type }) => {
  const previewText = PREVIEW_TEXTS[type];
  
  const previewStyle = {
    fontFamily: style.fontFamily,
    fontSize: `${style.fontSize}pt`,
    lineHeight: style.lineHeight,
    textAlign: style.alignment,
    fontWeight: style.isBold ? 'bold' : 'normal',
    fontStyle: style.isItalic ? 'italic' : 'normal',
    margin: 0,
    padding: 0
  };

  return (
    <div className="text-sm text-gray-600 mt-1">
      <p style={previewStyle}>{previewText}</p>
    </div>
  );
};
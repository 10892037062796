import React, { useState } from 'react';
import { Divide, Trash2, Check } from 'lucide-react';
import { TextBlockEditor } from './TextBlockEditor';
import { ImageBlockEditor } from './ImageBlockEditor';
import { EditorBlock } from '../../types/editor';

interface SplitBlockProps {
  block: EditorBlock;
  onChange: (updates: Partial<EditorBlock>) => void;
  onDelete: () => void;
  onSplit: (leftBlock: EditorBlock, rightBlock: EditorBlock) => void;
}

export const SplitBlock: React.FC<SplitBlockProps> = ({
  block,
  onChange,
  onDelete,
  onSplit,
}) => {
  const [splitRatio, setSplitRatio] = useState(0.5);
  const [isSplit, setIsSplit] = useState(false);
  const [leftType, setLeftType] = useState<'text' | 'image'>(block.type);
  const [rightType, setRightType] = useState<'text' | 'image'>(block.type);
  const [leftContent, setLeftContent] = useState(block.content);
  const [rightContent, setRightContent] = useState('');

  const handleSplit = () => {
    if (!isSplit) {
      setIsSplit(true);
    }
  };

  const handleApplySplit = () => {
    if (!leftContent && !rightContent) return;

    const leftBlock: EditorBlock = {
      id: crypto.randomUUID(),
      type: leftType,
      content: leftContent || '',
      styleType: leftType === 'text' ? block.styleType : undefined,
    };

    const rightBlock: EditorBlock = {
      id: crypto.randomUUID(),
      type: rightType,
      content: rightContent || '',
      styleType: rightType === 'text' ? block.styleType : undefined,
    };

    onSplit(leftBlock, rightBlock);
    setIsSplit(false);
  };

  const handleCancelSplit = () => {
    setIsSplit(false);
    setLeftContent(block.content);
    setRightContent('');
  };

  const handleDividerMove = (e: React.MouseEvent) => {
    const container = e.currentTarget.parentElement;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newRatio = Math.max(0.2, Math.min(0.8, x / rect.width));
    setSplitRatio(newRatio);
  };

  const renderBlockEditor = (type: 'text' | 'image', content: string, onChange: (content: string) => void) => {
    if (type === 'text') {
      return (
        <TextBlockEditor
          block={{ ...block, content }}
          onChange={(updates) => onChange(updates.content || '')}
          onDelete={() => {}}
        />
      );
    }
    return (
      <ImageBlockEditor
        block={{ ...block, type: 'image', content }}
        onChange={(updates) => onChange(updates.content || '')}
        onDelete={() => {}}
      />
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <button
            onClick={handleSplit}
            className="p-2 text-gray-400 hover:text-blue-500 transition-colors"
            title={isSplit ? "Mode division actif" : "Diviser"}
          >
            <Divide className="h-5 w-5" />
          </button>
          {isSplit && (
            <>
              <button
                onClick={handleApplySplit}
                className="p-2 text-gray-400 hover:text-green-500 transition-colors"
                title="Appliquer la division"
              >
                <Check className="h-5 w-5" />
              </button>
              <button
                onClick={handleCancelSplit}
                className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                title="Annuler la division"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </>
          )}
        </div>
        {!isSplit && (
          <button
            onClick={onDelete}
            className="p-2 text-gray-400 hover:text-red-500 transition-colors"
            title="Supprimer le bloc"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        )}
      </div>

      {isSplit ? (
        <div className="flex gap-4">
          <div style={{ width: `${splitRatio * 100}%` }} className="space-y-4">
            <select
              value={leftType}
              onChange={(e) => setLeftType(e.target.value as 'text' | 'image')}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="text">Texte</option>
              <option value="image">Image</option>
            </select>
            {renderBlockEditor(leftType, leftContent, setLeftContent)}
          </div>
          
          <div
            className="w-1 bg-blue-500 cursor-col-resize hover:bg-blue-600 active:bg-blue-700"
            onMouseDown={() => {
              const handleMouseMove = (e: MouseEvent) => {
                handleDividerMove(e as unknown as React.MouseEvent);
              };
              const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
              };
              document.addEventListener('mousemove', handleMouseMove);
              document.addEventListener('mouseup', handleMouseUp);
            }}
          />

          <div style={{ width: `${(1 - splitRatio) * 100}%` }} className="space-y-4">
            <select
              value={rightType}
              onChange={(e) => setRightType(e.target.value as 'text' | 'image')}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="text">Texte</option>
              <option value="image">Image</option>
            </select>
            {renderBlockEditor(rightType, rightContent, setRightContent)}
          </div>
        </div>
      ) : (
        <div>
          {block.type === 'text' ? (
            <TextBlockEditor
              block={block}
              onChange={onChange}
              onDelete={onDelete}
            />
          ) : (
            <ImageBlockEditor
              block={block}
              onChange={onChange}
              onDelete={onDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { useBookStore } from '../store/bookStore';
import { parseWordDocument } from '../utils/wordStyleParser';

export const FileUpload: React.FC = () => {
  const { setCurrentProject, currentProject } = useBookStore();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target?.result) {
          try {
            const arrayBuffer = event.target.result as ArrayBuffer;
            const blocks = await parseWordDocument(arrayBuffer);
            
            setCurrentProject({
              id: crypto.randomUUID(),
              title: file.name.replace(/\.[^/.]+$/, ""),
              content: blocks,
              size: currentProject?.size || {
                name: '6x9',
                width: 6,
                height: 9,
                description: 'Format standard pour la non-fiction'
              },
              styles: currentProject?.styles,
              lastModified: new Date()
            });
          } catch (error) {
            console.error('Erreur lors de la lecture du fichier:', error);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [setCurrentProject, currentProject]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: false
  });

  return (
    <div
      {...getRootProps()}
      className="col-span-2 border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-blue-500 transition-colors bg-white"
    >
      <input {...getInputProps()} />
      <Upload className="mx-auto h-12 w-12 text-gray-400" />
      <p className="mt-4 text-lg text-gray-600">
        {isDragActive
          ? 'Déposez le fichier ici...'
          : 'Glissez-déposez votre fichier Word ici, ou cliquez pour sélectionner'}
      </p>
      <p className="mt-2 text-sm text-gray-500">
        Seuls les fichiers .docx sont acceptés
      </p>
    </div>
  );
};
import React from 'react';
import { LayoutTemplate, Maximize, Image as ImageIcon } from 'lucide-react';
import { ImageLayout } from '../../types/editor';

interface ImageLayoutControlsProps {
  layout?: ImageLayout;
  onChange: (layout: ImageLayout) => void;
}

export const ImageLayoutControls: React.FC<ImageLayoutControlsProps> = ({
  layout = 'normal',
  onChange
}) => {
  return (
    <div className="flex gap-2">
      <button
        onClick={() => onChange('normal')}
        className={`flex items-center px-3 py-2 rounded-md text-sm ${
          layout === 'normal'
            ? 'bg-blue-100 text-blue-700'
            : 'text-gray-700 hover:bg-gray-100'
        }`}
        title="Taille normale"
      >
        <ImageIcon className="w-4 h-4 mr-2" />
        Normal
      </button>

      <button
        onClick={() => onChange('fullWidth')}
        className={`flex items-center px-3 py-2 rounded-md text-sm ${
          layout === 'fullWidth'
            ? 'bg-blue-100 text-blue-700'
            : 'text-gray-700 hover:bg-gray-100'
        }`}
        title="Pleine largeur"
      >
        <LayoutTemplate className="w-4 h-4 mr-2" />
        Pleine largeur
      </button>

      <button
        onClick={() => onChange('fullPage')}
        className={`flex items-center px-3 py-2 rounded-md text-sm ${
          layout === 'fullPage'
            ? 'bg-blue-100 text-blue-700'
            : 'text-gray-700 hover:bg-gray-100'
        }`}
        title="Pleine page"
      >
        <Maximize className="w-4 h-4 mr-2" />
        Pleine page
      </button>
    </div>
  );
};
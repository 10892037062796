import React from 'react';
import { TextStyle } from '../../types/styles';

const FONT_FAMILIES = [
  'Times New Roman',
  'Arial',
  'Calibri',
  'Cambria',
  'Georgia',
  'Helvetica',
  'Garamond',
  'Palatino',
  'Verdana'
];

const FONT_SIZES = Array.from({ length: 21 }, (_, i) => i + 8);
const LINE_HEIGHTS = [1, 1.15, 1.25, 1.5, 1.75, 2, 2.5];
const ALIGNMENTS = [
  { value: 'left', label: 'Gauche' },
  { value: 'center', label: 'Centre' },
  { value: 'right', label: 'Droite' }
];

const SPACING_LEVELS = [
  { value: 0.01, label: '1 - Minimal' },
  { value: 0.1, label: '2 - Très petit' },
  { value: 0.15, label: '3 - Petit' },
  { value: 0.2, label: '4 - Normal' },
  { value: 0.3, label: '5 - Grand' },
  { value: 0.4, label: '6 - Très grand' }
];

interface StyleControlsProps {
  style: TextStyle;
  onChange: (changes: Partial<TextStyle>) => void;
}

export const StyleControls: React.FC<StyleControlsProps> = ({ style, onChange }) => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Police</label>
          <select
            value={style.fontFamily}
            onChange={(e) => onChange({ fontFamily: e.target.value })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {FONT_FAMILIES.map(font => (
              <option key={font} value={font}>{font}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Taille (pt)</label>
          <select
            value={style.fontSize}
            onChange={(e) => onChange({ fontSize: Number(e.target.value) })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {FONT_SIZES.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Interligne</label>
          <select
            value={style.lineHeight}
            onChange={(e) => onChange({ lineHeight: Number(e.target.value) })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {LINE_HEIGHTS.map(height => (
              <option key={height} value={height}>{height}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Alignement</label>
          <select
            value={style.alignment}
            onChange={(e) => onChange({ alignment: e.target.value as TextStyle['alignment'] })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {ALIGNMENTS.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Espacement avant</label>
          <select
            value={style.marginTop}
            onChange={(e) => onChange({ marginTop: Number(e.target.value) })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {SPACING_LEVELS.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Espacement après</label>
          <select
            value={style.marginBottom}
            onChange={(e) => onChange({ marginBottom: Number(e.target.value) })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {SPACING_LEVELS.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={style.isBold}
            onChange={(e) => onChange({ isBold: e.target.checked })}
            className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="ml-2 text-sm text-gray-700">Gras</span>
        </label>

        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={style.isItalic}
            onChange={(e) => onChange({ isItalic: e.target.checked })}
            className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="ml-2 text-sm text-gray-700">Italique</span>
        </label>
      </div>
    </div>
  );
};
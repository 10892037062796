import React from 'react';
import { Upload } from 'lucide-react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

interface DropZoneProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  isDragActive: boolean;
}

export const DropZone: React.FC<DropZoneProps> = ({
  getRootProps,
  getInputProps,
  isDragActive
}) => (
  <div
    {...getRootProps()}
    className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
      ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-500'}`}
  >
    <input {...getInputProps()} />
    <Upload className="h-12 w-12 mx-auto text-gray-400" />
    <p className="mt-2 text-sm text-gray-600">
      {isDragActive ? "Déposez l'image ici..." : "Cliquez ou déposez une image ici"}
    </p>
  </div>
);
import { useState, useEffect, useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useBookStore } from '../store/bookStore';
import { EditorBlock } from '../types/editor';
import { StyleType } from '../types/styles';
import { splitContentIntoPages } from '../utils/pageCalculations';

export function useBlockManager(currentPage: number) {
  const [blocks, setBlocks] = useState<EditorBlock[]>([]);
  const { currentProject, setContent } = useBookStore();

  useEffect(() => {
    if (currentProject?.content) {
      const pages = splitContentIntoPages(currentProject.content);
      const pageBlocks = pages[currentPage] || [];

      const editorBlocks = pageBlocks.map((block) => ({
        id: crypto.randomUUID(),
        type: block.isImage ? 'image' : 'text',
        content: block.content,
        styleType: block.type as StyleType,
        layout: block.layout,
      }));

      setBlocks(editorBlocks);
    }
  }, [currentProject?.content, currentPage]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setBlocks((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const addBlock = useCallback((type: 'text' | 'image') => {
    const newBlock: EditorBlock = {
      id: crypto.randomUUID(),
      type,
      content: '',
      styleType: type === 'text' ? 'paragraph' : undefined,
      layout: type === 'image' ? 'normal' : undefined,
    };
    setBlocks((prev) => [...prev, newBlock]);
  }, []);

  const updateBlock = useCallback((id: string, updates: Partial<EditorBlock>) => {
    setBlocks((prev) =>
      prev.map((block) =>
        block.id === id ? { ...block, ...updates } : block
      )
    );
  }, []);

  const deleteBlock = useCallback((id: string) => {
    setBlocks((prev) => prev.filter((block) => block.id !== id));
  }, []);

  const handleSplit = useCallback((blockId: string, leftBlock: EditorBlock, rightBlock: EditorBlock) => {
    setBlocks((prev) => {
      const index = prev.findIndex((block) => block.id === blockId);
      if (index === -1) return prev;
      const newBlocks = [...prev];
      newBlocks.splice(index, 1, leftBlock, rightBlock);
      return newBlocks;
    });
  }, []);

  const saveBlocks = useCallback(() => {
    if (!currentProject?.content) return;

    const pages = splitContentIntoPages(currentProject.content);
    const updatedBlocks = blocks.map((block) => ({
      type: block.styleType || 'paragraph',
      content: block.content,
      isImage: block.type === 'image',
      layout: block.layout,
    }));

    pages[currentPage] = updatedBlocks;
    const updatedContent = pages.flat();
    setContent(updatedContent);
  }, [blocks, currentPage, currentProject?.content, setContent]);

  return {
    blocks,
    handleDragEnd,
    addBlock,
    updateBlock,
    deleteBlock,
    handleSplit,
    saveBlocks,
  };
}
import { create } from 'zustand';
import { BookProject, BookSize, TableOfContents } from '../types';
import { DocumentStyles } from '../types/styles';
import { DEFAULT_STYLES } from '../constants/defaultStyles';

interface BookStore {
  currentProject: BookProject | null;
  setCurrentProject: (project: BookProject) => void;
  updateBookSize: (size: BookSize) => void;
  updateStyles: (styles: DocumentStyles) => void;
  updateTableOfContents: (tableOfContents: TableOfContents) => void;
  setContent: (content: string) => void;
}

export const useBookStore = create<BookStore>((set) => ({
  currentProject: null,
  
  setCurrentProject: (project) => set({
    currentProject: {
      ...project,
      styles: { ...DEFAULT_STYLES, ...project.styles },
      tableOfContents: project.tableOfContents || { enabled: false, position: 'start' }
    }
  }),
  
  updateBookSize: (size) =>
    set((state) => ({
      currentProject: state.currentProject
        ? {
            ...state.currentProject,
            size,
            styles: { ...DEFAULT_STYLES, ...state.currentProject.styles }
          }
        : null
    })),
    
  updateStyles: (newStyles) =>
    set((state) => {
      if (!state.currentProject) return state;
      
      const updatedProject = {
        ...state.currentProject,
        styles: newStyles,
        lastModified: new Date()
      };
      
      return { currentProject: updatedProject };
    }),

  updateTableOfContents: (tableOfContents) =>
    set((state) => {
      if (!state.currentProject) return state;
      
      return {
        currentProject: {
          ...state.currentProject,
          tableOfContents,
          lastModified: new Date()
        }
      };
    }),
    
  setContent: (content) =>
    set((state) => ({
      currentProject: state.currentProject
        ? {
            ...state.currentProject,
            content,
            lastModified: new Date()
          }
        : null
    }))
}));
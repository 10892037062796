import React from 'react';
import { FileText, Book } from 'lucide-react';
import { Modal } from '../Modal';
import { useExport } from '../../hooks/useExport';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {
  const { exportToPDF, exportToEPUB, isExporting } = useExport();

  const handleExport = async (format: 'pdf' | 'epub') => {
    if (format === 'pdf') {
      await exportToPDF();
    } else {
      await exportToEPUB();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Exporter le livre"
    >
      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => handleExport('pdf')}
          disabled={isExporting}
          className="flex flex-col items-center p-6 border rounded-lg hover:bg-gray-50 transition-colors disabled:opacity-50"
        >
          <FileText className="h-12 w-12 text-blue-600 mb-3" />
          <span className="font-medium">PDF</span>
          <span className="text-sm text-gray-500 mt-1">Format imprimable</span>
        </button>

        <button
          onClick={() => handleExport('epub')}
          disabled={isExporting}
          className="flex flex-col items-center p-6 border rounded-lg hover:bg-gray-50 transition-colors disabled:opacity-50"
        >
          <Book className="h-12 w-12 text-blue-600 mb-3" />
          <span className="font-medium">EPUB</span>
          <span className="text-sm text-gray-500 mt-1">Format e-book</span>
        </button>
      </div>
    </Modal>
  );
};
import React, { useState } from 'react';
import { Book, Layout as LayoutIcon, Type, Eye, Edit, FileText } from 'lucide-react';
import { useBookStore } from '../store/bookStore';
import { BookSizeSelector } from './BookSizeSelector';
import { FileUpload } from './FileUpload';
import { StyleSettings } from './StyleSettings';
import { PreviewContainer } from './preview/PreviewContainer';
import { Editor } from './editor/Editor';
import { PageLayout } from './layout/PageLayout';
import { useProjectSync } from '../hooks/useProjectSync';
import { ExportButton } from './export/ExportButton';

type MenuItem = {
  id: string;
  icon: React.ElementType;
  label: string;
  component: React.ComponentType;
};

const menuItems: MenuItem[] = [
  {
    id: 'manuscript',
    icon: FileText,
    label: 'Manuscrit',
    component: FileUpload
  },
  {
    id: 'book-size',
    icon: Book,
    label: 'Taille du livre',
    component: BookSizeSelector
  },
  {
    id: 'typography',
    icon: Type,
    label: 'Typographie',
    component: StyleSettings
  },
  {
    id: 'layout',
    icon: LayoutIcon,
    label: 'Mise en page',
    component: PageLayout
  },
  {
    id: 'editor',
    icon: Edit,
    label: 'Éditeur',
    component: Editor
  },
  {
    id: 'preview',
    icon: Eye,
    label: 'Prévisualisation',
    component: PreviewContainer
  }
];

export const Layout: React.FC = () => {
  const [activeMenuItem, setActiveMenuItem] = useState('manuscript');
  const { currentProject } = useBookStore();
  
  useProjectSync();

  const ActiveComponent = menuItems.find(item => item.id === activeMenuItem)?.component;

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Book className="h-8 w-8 text-blue-600" />
              <h1 className="text-2xl font-bold text-gray-900">
                Assistant de Mise en Page KDP
              </h1>
            </div>
            {currentProject && <ExportButton />}
          </div>
        </div>
      </header>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex gap-4">
          <nav className="w-48 flex-shrink-0">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              {menuItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setActiveMenuItem(item.id)}
                  className={`w-full flex items-center space-x-3 px-4 py-3 text-left transition-colors
                    ${activeMenuItem === item.id
                      ? 'bg-blue-50 text-blue-700 border-l-4 border-blue-500'
                      : 'text-gray-600 hover:bg-gray-50'
                    }`}
                >
                  <item.icon className="h-5 w-5 flex-shrink-0" />
                  <span className="font-medium truncate">{item.label}</span>
                </button>
              ))}
            </div>
          </nav>

          <div className="flex-1">
            <div className="bg-white rounded-lg shadow-lg p-6">
              {ActiveComponent && <ActiveComponent />}
            </div>

            {currentProject && activeMenuItem !== 'editor' && activeMenuItem !== 'preview' && (
              <div className="mt-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">
                  Aperçu
                </h2>
                <PreviewContainer readOnly />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
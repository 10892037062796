import React from 'react';
import { Type, Image as ImageIcon, Plus } from 'lucide-react';
import { Modal } from '../Modal';

interface BlockControlsProps {
  onAddBlock: (type: 'text' | 'image') => void;
}

export const BlockControls: React.FC<BlockControlsProps> = ({ onAddBlock }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="w-full py-3 border-2 border-dashed border-gray-300 rounded-lg text-gray-500 hover:border-blue-500 hover:text-blue-500 flex items-center justify-center mt-4"
      >
        <Plus className="w-5 h-5 mr-2" />
        Ajouter un bloc
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Ajouter un bloc"
      >
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => {
              onAddBlock('text');
              setIsModalOpen(false);
            }}
            className="flex flex-col items-center p-4 border rounded-lg hover:bg-gray-50"
          >
            <Type className="h-8 w-8 text-gray-600 mb-2" />
            <span className="text-sm font-medium">Texte</span>
          </button>
          <button
            onClick={() => {
              onAddBlock('image');
              setIsModalOpen(false);
            }}
            className="flex flex-col items-center p-4 border rounded-lg hover:bg-gray-50"
          >
            <ImageIcon className="h-8 w-8 text-gray-600 mb-2" />
            <span className="text-sm font-medium">Image</span>
          </button>
        </div>
      </Modal>
    </>
  );
};
import { useState, useEffect, useCallback } from 'react';
import { Project } from '../types/auth';
import { api } from '../lib/api';

export function useProjects() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProjectsList = useCallback(async () => {
    try {
      const results = await api.get<Project[]>('/projects');
      setProjects(results);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteProject = useCallback(async (projectId: string) => {
    try {
      await api.delete(`/projects/${projectId}`);
      setProjects(prev => prev.filter(project => project.id !== projectId));
      return true;
    } catch (err: any) {
      setError(err.message);
      return false;
    }
  }, []);

  useEffect(() => {
    fetchProjectsList();
  }, [fetchProjectsList]);

  return { 
    projects, 
    loading, 
    error, 
    deleteProject, 
    refreshProjects: fetchProjectsList 
  };
}
import React, { useState } from 'react';
import { useBookStore } from '../../store/bookStore';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ParsedBlock } from '../../types/content';
import { generateTableOfContents } from '../../utils/tableOfContents';
import { splitContentIntoPages } from '../../utils/pageCalculations';
import { PreviewControls } from './PreviewControls';
import { PageGrid } from './PageGrid';

interface PreviewContainerProps {
  readOnly?: boolean;
}

export const PreviewContainer: React.FC<PreviewContainerProps> = ({ readOnly = false }) => {
  const { currentProject } = useBookStore();
  const [currentPage, setCurrentPage] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [pagesPerRow, setPagesPerRow] = useState(1);
  
  const pages = React.useMemo(() => {
    if (!currentProject?.content) return [];

    const allPages: ParsedBlock[][] = [];
    
    if (currentProject.tableOfContents?.enabled && currentProject.tableOfContents.position === 'start') {
      const tocBlocks = generateTableOfContents(currentProject.content);
      allPages.push(tocBlocks);
    }

    const contentPages = splitContentIntoPages(currentProject.content);
    allPages.push(...contentPages);

    if (currentProject.tableOfContents?.enabled && currentProject.tableOfContents.position === 'end') {
      const tocBlocks = generateTableOfContents(currentProject.content);
      allPages.push(tocBlocks);
    }

    return allPages;
  }, [currentProject?.content, currentProject?.tableOfContents]);

  if (!currentProject?.content || pages.length === 0) {
    return null;
  }

  const endPage = Math.min(currentPage + pagesPerRow - 1, pages.length - 1);

  return (
    <div className="w-full">
      <PreviewControls
        zoom={zoom}
        onZoomChange={setZoom}
        pagesPerRow={pagesPerRow}
        onPagesPerRowChange={setPagesPerRow}
      />

      <div className="flex items-center justify-center space-x-4 mb-4">
        <button
          onClick={() => setCurrentPage(p => Math.max(0, p - pagesPerRow))}
          disabled={currentPage === 0}
          className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        
        <span className="text-sm text-gray-600">
          Pages {currentPage + 1}-{endPage + 1} sur {pages.length}
        </span>
        
        <button
          onClick={() => setCurrentPage(p => Math.min(pages.length - pagesPerRow, p + pagesPerRow))}
          disabled={endPage === pages.length - 1}
          className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      <div className="overflow-auto py-8 flex justify-center">
        <PageGrid
          pages={pages}
          zoom={zoom}
          pagesPerRow={pagesPerRow}
          startPage={currentPage}
          endPage={endPage}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
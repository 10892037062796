import { BookSize } from '../types';

export const KDP_BOOK_SIZES: BookSize[] = [
  {
    name: '5.5x8.5',
    width: 5.5,
    height: 8.5,
    description: 'Format roman standard',
    hasBleed: false
  },
  {
    name: '6x9',
    width: 6,
    height: 9,
    description: 'Format standard pour la non-fiction',
    hasBleed: false
  },
  {
    name: '8.5x11',
    width: 8.5,
    height: 11,
    description: 'Format lettre, parfait pour les manuels',
    hasBleed: false
  }
];
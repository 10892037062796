import React from 'react';
import { useBookStore } from '../../store/bookStore';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortablePage } from './SortablePage';
import { splitContentIntoPages } from '../../utils/pageManagement';

export const PageLayout: React.FC = () => {
  const { currentProject, setContent } = useBookStore();
  const pages = React.useMemo(() => {
    if (!currentProject?.content) return [];
    return splitContentIntoPages(currentProject.content);
  }, [currentProject?.content]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = pages.findIndex((_, index) => `page-${index}` === active.id);
    const newIndex = pages.findIndex((_, index) => `page-${index}` === over.id);

    const newPages = [...pages];
    const [movedPage] = newPages.splice(oldIndex, 1);
    newPages.splice(newIndex, 0, movedPage);

    const newContent = newPages.flat();
    setContent(newContent);
  };

  if (!currentProject) return null;

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-medium text-gray-900">Organisation des pages</h2>
      <p className="text-sm text-gray-500">
        Faites glisser les pages pour réorganiser leur ordre dans le document.
      </p>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={pages.map((_, index) => `page-${index}`)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-4">
            {pages.map((pageContent, index) => (
              <SortablePage
                key={`page-${index}`}
                id={`page-${index}`}
                pageNumber={index + 1}
                content={pageContent}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};
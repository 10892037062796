import { BookDimensions } from '../types/dimensions';

// Dimensions en pouces
export const BOOK_DIMENSIONS: Record<string, BookDimensions> = {
  '5.5x8.5': {
    width: 5.5,
    height: 8.5,
    bleed: 0.125,
    margins: {
      small: { // 24-150 pages
        inside: 0.375,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      medium: { // 151-300 pages
        inside: 0.5,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      large: { // 300+ pages
        inside: 0.625,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      }
    },
    withBleed: {
      width: 5.75,
      height: 8.75
    }
  },
  '6x9': {
    width: 6,
    height: 9,
    bleed: 0.125,
    margins: {
      small: {
        inside: 0.375,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      medium: {
        inside: 0.5,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      large: {
        inside: 0.625,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      }
    },
    withBleed: {
      width: 6.25,
      height: 9.25
    }
  },
  '8.5x11': {
    width: 8.5,
    height: 11,
    bleed: 0.125,
    margins: {
      small: { // 24-150 pages
        inside: 0.5,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      medium: { // 151-300 pages
        inside: 0.625,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      },
      large: { // 300+ pages
        inside: 0.75,
        outside: 0.25,
        top: 0.5,
        bottom: 0.5
      }
    },
    withBleed: {
      width: 8.75,
      height: 11.25
    }
  }
};
import { DocumentStyles } from '../types/styles';

export const DEFAULT_STYLES: DocumentStyles = {
  paragraph: {
    fontFamily: 'Times New Roman',
    fontSize: 12,
    lineHeight: 1.15,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 1,
    marginRight: 1,
    alignment: 'left'
  },
  heading1: {
    fontFamily: 'Times New Roman',
    fontSize: 16,
    lineHeight: 1.5,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 1,
    marginRight: 1,
    isBold: true,
    alignment: 'left'
  },
  heading2: {
    fontFamily: 'Times New Roman',
    fontSize: 14,
    lineHeight: 1.5,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 1,
    marginRight: 1,
    isBold: true,
    alignment: 'left'
  },
  heading3: {
    fontFamily: 'Times New Roman',
    fontSize: 12,
    lineHeight: 1.5,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 1,
    marginRight: 1,
    isBold: true,
    alignment: 'left'
  },
  blockquote: {
    fontFamily: 'Times New Roman',
    fontSize: 12,
    lineHeight: 1.15,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 1,
    marginRight: 1,
    isItalic: true,
    alignment: 'left'
  },
  strong: {
    fontFamily: 'Times New Roman',
    fontSize: 12,
    lineHeight: 1.15,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 0,
    marginRight: 0,
    isBold: true,
    alignment: 'left'
  },
  emphasis: {
    fontFamily: 'Times New Roman',
    fontSize: 12,
    lineHeight: 1.15,
    marginTop: 0.2,
    marginBottom: 0.2,
    marginLeft: 0,
    marginRight: 0,
    isItalic: true,
    alignment: 'left'
  }
};
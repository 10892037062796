import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useBookStore } from '../../store/bookStore';
import { PageEditor } from './PageEditor';
import { splitContentIntoPages } from '../../utils/pageCalculations';

export const Editor: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { currentProject } = useBookStore();

  const pages = React.useMemo(() => {
    if (!currentProject?.content) return [];
    return splitContentIntoPages(currentProject.content);
  }, [currentProject?.content]);

  if (!currentProject) return null;

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pages.length) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-6 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          
          <span className="text-sm text-gray-600">
            Page {currentPage + 1} sur {pages.length || 1}
          </span>
          
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pages.length - 1}
            className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>

        <select
          value={currentPage}
          onChange={(e) => handlePageChange(Number(e.target.value))}
          className="block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
        >
          {Array.from({ length: pages.length || 1 }, (_, i) => (
            <option key={i} value={i}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>

      <PageEditor currentPage={currentPage} />
    </div>
  );
};
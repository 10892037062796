import mammoth from 'mammoth';
import { StyleType } from '../types/styles';
import { ParsedBlock } from '../types/content';

// Configuration améliorée pour la détection des styles Word
const WORD_STYLE_MAP = [
  // Styles de paragraphe
  "p[style-name='Normal'] => p:fresh",
  "p[style-name='Body Text'] => p:fresh",
  "p[style-name='Corps de texte'] => p:fresh",
  
  // Titres - inclure toutes les variations possibles
  "p[style-name='Title'] => h1:fresh",
  "p[style-name='Titre'] => h1:fresh",
  "p[style-name='Heading 1'] => h1:fresh",
  "p[style-name='Titre 1'] => h1:fresh",
  "p[style-name='heading 1'] => h1:fresh",
  
  "p[style-name='Heading 2'] => h2:fresh",
  "p[style-name='Titre 2'] => h2:fresh",
  "p[style-name='heading 2'] => h2:fresh",
  
  "p[style-name='Heading 3'] => h3:fresh",
  "p[style-name='Titre 3'] => h3:fresh",
  "p[style-name='heading 3'] => h3:fresh",
  
  // Styles spéciaux
  "p[style-name='Quote'] => blockquote:fresh",
  "p[style-name='Citation'] => blockquote:fresh",
  
  // Styles en ligne
  "strong => strong:fresh",
  "b => strong:fresh",
  "em => em:fresh",
  "i => em:fresh"
];

const HTML_TO_STYLE_TYPE: Record<string, StyleType> = {
  'p': 'paragraph',
  'h1': 'heading1',
  'h2': 'heading2',
  'h3': 'heading3',
  'strong': 'strong',
  'em': 'emphasis',
  'blockquote': 'blockquote'
};

export async function parseWordDocument(arrayBuffer: ArrayBuffer): Promise<ParsedBlock[]> {
  try {
    // Options de conversion avec debug activé
    const options = {
      arrayBuffer,
      styleMap: WORD_STYLE_MAP,
      includeDefaultStyleMap: true,
      transformDocument: (document: any) => {
        // Log pour debug
        console.log('Structure du document:', document);
        return document;
      }
    };

    const result = await mammoth.convertToHtml(options);
    
    // Log des messages de conversion pour debug
    console.log('HTML généré:', result.value);
    console.log('Messages de conversion:', result.messages);

    const parser = new DOMParser();
    const doc = parser.parseFromString(result.value, 'text/html');
    const blocks: ParsedBlock[] = [];

    function processNode(node: Node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        const tagName = element.tagName.toLowerCase();
        const content = element.textContent?.trim() || '';
        
        // Log pour debug
        console.log('Style détecté:', tagName, 'pour le contenu:', content);
        
        if (content && HTML_TO_STYLE_TYPE[tagName]) {
          blocks.push({
            type: HTML_TO_STYLE_TYPE[tagName],
            content: content
          });
        }
      }
    }

    doc.body.childNodes.forEach(processNode);

    // Filtrer les blocs vides
    return blocks.filter(block => block.content.length > 0);
  } catch (error) {
    console.error('Erreur lors du parsing du document Word:', error);
    throw error;
  }
}
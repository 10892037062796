import { ParsedBlock } from '../types/content';

export function generateTableOfContents(content: ParsedBlock[]): ParsedBlock[] {
  const headings = content.filter(block => block.type === 'heading1');
  
  if (headings.length === 0) {
    return [{
      type: 'paragraph',
      content: 'Aucun titre de niveau 1 trouvé pour générer la table des matières'
    }];
  }

  const tocBlocks: ParsedBlock[] = [
    {
      type: 'heading1',
      content: 'Table des matières'
    }
  ];

  headings.forEach((heading, index) => {
    tocBlocks.push({
      type: 'paragraph',
      content: `${heading.content}${'\u00A0'.repeat(20)}${index + 1}`
    });
  });

  return tocBlocks;
}
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical } from 'lucide-react';
import { SplitBlock } from './SplitBlock';
import { EditorBlock } from '../../types/editor';

interface DraggableBlockProps {
  block: EditorBlock;
  onChange: (updates: Partial<EditorBlock>) => void;
  onDelete: () => void;
  onSplit: (leftBlock: EditorBlock, rightBlock: EditorBlock) => void;
}

export const DraggableBlock: React.FC<DraggableBlockProps> = ({
  block,
  onChange,
  onDelete,
  onSplit,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: block.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="border rounded-lg p-4 bg-white"
    >
      <div className="flex items-start gap-2">
        <div
          className="p-2 cursor-grab hover:bg-gray-100 rounded-lg"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="w-4 h-4 text-gray-400" />
        </div>
        
        <div className="flex-1" onClick={(e) => e.stopPropagation()}>
          <SplitBlock
            block={block}
            onChange={onChange}
            onDelete={onDelete}
            onSplit={onSplit}
          />
        </div>
      </div>
    </div>
  );
};
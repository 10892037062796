import React from 'react';
import { useBookStore } from '../store/bookStore';
import { StyleSection } from './StyleSettings/StyleSection';
import { StyleControls } from './StyleSettings/StyleControls';
import { StyleType } from '../types/styles';
import { Save } from 'lucide-react';

const STYLE_SECTIONS = [
  { type: 'paragraph' as StyleType, title: 'Corps de texte' },
  { type: 'heading1' as StyleType, title: 'Titre 1' },
  { type: 'heading2' as StyleType, title: 'Titre 2' },
  { type: 'heading3' as StyleType, title: 'Titre 3' },
  { type: 'blockquote' as StyleType, title: 'Citation' },
  { type: 'strong' as StyleType, title: 'Texte en gras' },
  { type: 'emphasis' as StyleType, title: 'Texte en italique' }
];

export const StyleSettings: React.FC = () => {
  const [localStyles, setLocalStyles] = React.useState<any>(null);
  const { currentProject, updateStyles } = useBookStore();
  
  React.useEffect(() => {
    if (currentProject?.styles) {
      setLocalStyles(currentProject.styles);
    }
  }, [currentProject?.styles]);

  const handleStyleChange = (type: StyleType, changes: any) => {
    setLocalStyles(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        ...changes
      }
    }));
  };

  const handleApplyStyles = () => {
    if (localStyles) {
      updateStyles(localStyles);
    }
  };

  if (!localStyles) return null;

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {STYLE_SECTIONS.map(({ type, title }) => (
          <StyleSection
            key={type}
            type={type}
            title={title}
            style={localStyles[type]}
            onChange={(changes) => handleStyleChange(type, changes)}
          >
            <StyleControls
              style={localStyles[type]}
              onChange={(changes) => handleStyleChange(type, changes)}
            />
          </StyleSection>
        ))}
      </div>

      <div className="flex justify-center pt-4">
        <button
          onClick={handleApplyStyles}
          className="flex items-center px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Save className="w-5 h-5 mr-2" />
          Appliquer
        </button>
      </div>
    </div>
  );
};
import React, { useEffect } from 'react';
import { KDP_BOOK_SIZES } from '../constants/bookSizes';
import { useBookStore } from '../store/bookStore';
import { BookSize } from '../types';

export const BookSizeSelector: React.FC = () => {
  const { currentProject, updateBookSize } = useBookStore();
  const [hasBleed, setHasBleed] = React.useState(currentProject?.size?.hasBleed || false);

  useEffect(() => {
    setHasBleed(currentProject?.size?.hasBleed || false);
  }, [currentProject?.size?.hasBleed]);

  const handleSizeChange = (size: BookSize) => {
    updateBookSize({ ...size, hasBleed });
  };

  const handleBleedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHasBleed = e.target.checked;
    setHasBleed(newHasBleed);
    if (currentProject?.size) {
      updateBookSize({ ...currentProject.size, hasBleed: newHasBleed });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          id="hasBleed"
          checked={hasBleed}
          onChange={handleBleedChange}
          className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
        />
        <label htmlFor="hasBleed" className="text-sm text-gray-700">
          Ajouter un fond perdu (0.125" sur chaque bord)
        </label>
        <div className="ml-2 group relative">
          <span className="text-gray-400 cursor-help">?</span>
          <div className="hidden group-hover:block absolute left-0 bottom-6 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg">
            Activez cette option si vous avez des images ou des arrière-plans qui vont jusqu'au bord de la page.
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {KDP_BOOK_SIZES.map((size) => {
          const dimensions = hasBleed 
            ? `${size.width + 0.25}" x ${size.height + 0.25}"`
            : `${size.width}" x ${size.height}"`;

          return (
            <button
              key={size.name}
              onClick={() => handleSizeChange(size)}
              className={`p-6 rounded-lg border-2 transition-all ${
                currentProject?.size?.name === size.name
                  ? 'border-blue-500 bg-blue-50'
                  : 'border-gray-200 hover:border-blue-300'
              }`}
            >
              <h3 className="text-lg font-semibold">{size.name}</h3>
              <p className="text-sm text-gray-600 mt-2">{size.description}</p>
              <div className="mt-4 text-sm text-gray-500">
                {dimensions}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
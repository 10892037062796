import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DraggableBlock } from './DraggableBlock';
import { useBlockManager } from '../../hooks/useBlockManager';
import { BlockControls } from './BlockControls';
import { EditorBlock } from '../../types/editor';

interface PageEditorProps {
  currentPage: number;
}

export const PageEditor: React.FC<PageEditorProps> = ({ currentPage }) => {
  const {
    blocks,
    handleDragEnd,
    addBlock,
    updateBlock,
    deleteBlock,
    handleSplit,
    saveBlocks,
  } = useBlockManager(currentPage);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSplitBlock = (blockId: string, leftBlock: EditorBlock, rightBlock: EditorBlock) => {
    handleSplit(blockId, leftBlock, rightBlock);
  };

  return (
    <div className="space-y-4">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={blocks.map((block) => block.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-4">
            {blocks.map((block) => (
              <DraggableBlock
                key={block.id}
                block={block}
                onChange={(updates) => updateBlock(block.id, updates)}
                onDelete={() => deleteBlock(block.id)}
                onSplit={(leftBlock, rightBlock) => handleSplitBlock(block.id, leftBlock, rightBlock)}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      <BlockControls onAddBlock={addBlock} />

      <div className="flex justify-end">
        <button
          onClick={saveBlocks}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Enregistrer les modifications
        </button>
      </div>
    </div>
  );
};
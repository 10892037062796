import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageBlockEditorProps } from '../../types/editor';
import { DropZone } from './DropZone';
import { ImageLayoutControls } from './ImageLayoutControls';
import { ImageDisplay } from '../common/ImageDisplay';

export const ImageBlockEditor: React.FC<ImageBlockEditorProps> = ({
  block,
  onChange,
  onDelete
}) => {
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        onChange({ content: result });
      };
      reader.readAsDataURL(file);
    }
  }, [onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp']
    },
    multiple: false
  });

  if (!block.content) {
    return (
      <DropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
      />
    );
  }

  return (
    <div className="space-y-4">
      <ImageLayoutControls
        layout={block.layout}
        onChange={(layout) => onChange({ layout })}
      />
      
      <div className={`relative ${
        block.layout === 'fullPage' ? '-mx-8 -my-4' : 
        block.layout === 'fullWidth' ? '-mx-8' : ''
      }`}>
        <ImageDisplay
          src={block.content}
          alt="Contenu téléchargé"
          className={`
            ${block.layout === 'fullWidth' ? 'w-full' : ''}
            ${block.layout === 'fullPage' ? 'w-full h-[calc(100vh-20rem)] object-cover' : ''}
          `}
        />
        
        <div
          {...getRootProps()}
          className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity cursor-pointer rounded-lg"
        >
          <input {...getInputProps()} />
          <div className="text-white text-center">
            <p className="text-sm">Cliquez ou déposez pour remplacer</p>
          </div>
        </div>
      </div>
    </div>
  );
};
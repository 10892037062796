import { BookProject } from '../../types';
import { splitContentIntoPages } from '../pageManagement';

function generateEPUBContainer(): string {
  return `<?xml version="1.0" encoding="UTF-8"?>
<container version="1.0" xmlns="urn:oasis:names:tc:opendocument:xmlns:container">
  <rootfiles>
    <rootfile full-path="OEBPS/content.opf" media-type="application/oebps-package+xml"/>
  </rootfiles>
</container>`;
}

function generateContentOPF(project: BookProject, sections: string[]): string {
  const items = sections.map((_, index) => 
    `<item id="section${index}" href="sections/section${index}.xhtml" media-type="application/xhtml+xml"/>`
  ).join('\n');

  return `<?xml version="1.0" encoding="UTF-8"?>
<package xmlns="http://www.idpf.org/2007/opf" version="3.0" unique-identifier="BookId">
  <metadata xmlns:dc="http://purl.org/dc/elements/1.1/">
    <dc:title>${project.title}</dc:title>
    <dc:language>fr</dc:language>
    <dc:identifier id="BookId">urn:uuid:${crypto.randomUUID()}</dc:identifier>
  </metadata>
  <manifest>
    ${items}
  </manifest>
  <spine>
    ${sections.map((_, index) => `<itemref idref="section${index}"/>`).join('\n')}
  </spine>
</package>`;
}

function generateSection(content: string, index: number): string {
  return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <title>Section ${index + 1}</title>
  <style>
    body { margin: 5%; line-height: 1.4; }
    img { max-width: 100%; height: auto; }
  </style>
</head>
<body>
  ${content}
</body>
</html>`;
}

export async function generateEPUB(project: BookProject): Promise<void> {
  try {
    const pages = splitContentIntoPages(project.content);
    const sections = pages.map((pageContent, pageIndex) => {
      let htmlContent = '';
      
      pageContent.forEach((block) => {
        if (block.isImage) {
          htmlContent += `<div class="image-container ${block.layout || ''}">
            <img src="${block.content}" alt="" />
          </div>`;
        } else {
          const style = project.styles[block.type];
          htmlContent += `<div style="
            font-family: ${style.fontFamily};
            font-size: ${style.fontSize}pt;
            line-height: ${style.lineHeight};
            text-align: ${style.alignment};
            ${style.isBold ? 'font-weight: bold;' : ''}
            ${style.isItalic ? 'font-style: italic;' : ''}
          ">${block.content}</div>`;
        }
      });

      return generateSection(htmlContent, pageIndex);
    });

    const container = generateEPUBContainer();
    const contentOpf = generateContentOPF(project, sections);

    // Create ZIP file with EPUB structure
    const zip = new JSZip();
    zip.file('mimetype', 'application/epub+zip');
    zip.file('META-INF/container.xml', container);
    zip.file('OEBPS/content.opf', contentOpf);
    
    sections.forEach((section, index) => {
      zip.file(`OEBPS/sections/section${index}.xhtml`, section);
    });

    const epubBlob = await zip.generateAsync({ type: 'blob' });
    
    // Download the EPUB file
    const url = URL.createObjectURL(epubBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.title}.epub`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Erreur lors de la génération de l\'EPUB:', error);
    throw error;
  }
}
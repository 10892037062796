import { BookSize } from '../types';
import { ParsedBlock } from '../types/content';
import { BOOK_DIMENSIONS } from '../constants/bookDimensions';

export const calculatePageDimensions = (size?: BookSize) => {
  if (!size) {
    return { width: 6, height: 9 }; // Default size
  }

  const dimensions = BOOK_DIMENSIONS[size.name];
  if (!dimensions) {
    return { width: size.width, height: size.height };
  }

  // Retourner les dimensions avec fond perdu si nécessaire
  return {
    width: dimensions.withBleed.width,
    height: dimensions.withBleed.height,
    margins: dimensions.margins,
    bleed: dimensions.bleed
  };
};

export const calculateWordsPerPage = (size?: BookSize) => {
  if (!size) return 250; // Default words per page
  
  const dimensions = BOOK_DIMENSIONS[size.name];
  if (!dimensions) {
    const area = size.width * size.height;
    return Math.floor(area * 40);
  }

  // Calculer la zone de texte disponible en tenant compte des marges
  const margins = dimensions.margins.medium; // Utiliser les marges moyennes par défaut
  const textWidth = dimensions.width - (margins.inside + margins.outside);
  const textHeight = dimensions.height - (margins.top + margins.bottom);
  const textArea = textWidth * textHeight;

  // Estimation plus précise basée sur la zone de texte disponible
  return Math.floor(textArea * 45); // ~45 mots par pouce carré avec une police de 11pt
};

export const splitContentIntoPages = (blocks: ParsedBlock[]): ParsedBlock[][] => {
  const pages: ParsedBlock[][] = [];
  let currentPage: ParsedBlock[] = [];
  let currentWordCount = 0;
  const TARGET_WORDS_PER_PAGE = 250;

  blocks.forEach((block) => {
    if (block.type === 'heading1' && currentPage.length > 0) {
      pages.push([...currentPage]);
      currentPage = [];
      currentWordCount = 0;
    }

    if (!block.isImage) {
      const blockWordCount = block.content.split(/\s+/).length;
      if (currentWordCount + blockWordCount > TARGET_WORDS_PER_PAGE && currentPage.length > 0) {
        pages.push([...currentPage]);
        currentPage = [];
        currentWordCount = 0;
      }
      currentWordCount += blockWordCount;
    }

    currentPage.push(block);
  });

  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
};
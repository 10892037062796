import React from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { StyleSectionProps } from './types';
import { PreviewText } from './PreviewText';

export const StyleSection: React.FC<StyleSectionProps> = ({
  title,
  children,
  type,
  style,
  onChange
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="border rounded-lg overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-2 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
      >
        <div className="flex flex-col items-start">
          <span className="font-medium text-gray-700 text-sm">{title}</span>
          <PreviewText style={style} type={type} />
        </div>
        {isExpanded ? (
          <ChevronDown className="w-4 h-4 text-gray-500 flex-shrink-0" />
        ) : (
          <ChevronRight className="w-4 h-4 text-gray-500 flex-shrink-0" />
        )}
      </button>
      
      {isExpanded && (
        <div className="p-4 border-t">
          {children}
        </div>
      )}
    </div>
  );
};
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBookStore } from '../store/bookStore';
import { api } from '../lib/api';

export function useProjectSync() {
  const { id } = useParams();
  const { currentProject, setCurrentProject } = useBookStore();
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Charger le projet
  useEffect(() => {
    if (!id) return;

    const loadProject = async () => {
      try {
        const data = await api.get(`/projects/${id}`);
        if (data) {
          setCurrentProject({
            id: data.id,
            title: data.title,
            content: JSON.parse(data.content || '[]'),
            size: JSON.parse(data.size || '{}'),
            styles: JSON.parse(data.styles || '{}'),
            tableOfContents: JSON.parse(data.tableOfContents || '{"enabled":false,"position":"start"}'),
            lastModified: new Date(data.updated_at)
          });
        }
      } catch (error: any) {
        console.error('Erreur lors du chargement:', error);
        setError(error.message);
      }
    };

    loadProject();
  }, [id, setCurrentProject]);

  // Sauvegarder les modifications
  useEffect(() => {
    if (!currentProject || !id) return;

    const saveTimeout = setTimeout(async () => {
      try {
        setIsSaving(true);
        await api.put(`/projects/${id}`, {
          content: JSON.stringify(currentProject.content),
          size: JSON.stringify(currentProject.size),
          styles: JSON.stringify(currentProject.styles),
          tableOfContents: JSON.stringify(currentProject.tableOfContents)
        });
      } catch (error: any) {
        console.error('Erreur lors de la sauvegarde:', error);
        setError(error.message);
      } finally {
        setIsSaving(false);
      }
    }, 2000);

    return () => clearTimeout(saveTimeout);
  }, [currentProject, id]);

  return { error, isSaving };
}
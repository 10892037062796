import React from 'react';
import { Trash2 } from 'lucide-react';
import { StyleType } from '../../types/styles';

interface TextBlockEditorProps {
  block: {
    content: string;
    styleType?: StyleType;
  };
  onChange: (updates: { content?: string; styleType?: StyleType }) => void;
  onDelete: () => void;
}

const STYLE_OPTIONS: { value: StyleType; label: string }[] = [
  { value: 'paragraph', label: 'Paragraphe' },
  { value: 'heading1', label: 'Titre 1' },
  { value: 'heading2', label: 'Titre 2' },
  { value: 'heading3', label: 'Titre 3' },
  { value: 'blockquote', label: 'Citation' }
];

export const TextBlockEditor: React.FC<TextBlockEditorProps> = ({
  block,
  onChange,
  onDelete
}) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-start">
        <select
          value={block.styleType}
          onChange={(e) => onChange({ styleType: e.target.value as StyleType })}
          className="block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
        >
          {STYLE_OPTIONS.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <button
          onClick={onDelete}
          className="p-2 text-gray-400 hover:text-red-500"
          title="Supprimer le bloc"
        >
          <Trash2 className="h-5 w-5" />
        </button>
      </div>

      <textarea
        value={block.content}
        onChange={(e) => onChange({ content: e.target.value })}
        className="w-full h-32 p-3 border rounded-md focus:ring-blue-500 focus:border-blue-500"
        placeholder="Entrez votre texte ici..."
      />
    </div>
  );
};
import { jsPDF } from 'jspdf';
import { BookProject } from '../../types';
import { calculatePageDimensions } from '../pageCalculations';
import { getPageMargins } from '../pageMargins';
import { splitContentIntoPages } from '../pageManagement';

async function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export async function generatePDF(project: BookProject): Promise<void> {
  try {
    const dimensions = calculatePageDimensions(project.size);
    const pdf = new jsPDF({
      unit: 'in',
      format: [dimensions.width, dimensions.height],
      orientation: 'portrait'
    });

    const pages = splitContentIntoPages(project.content);
    
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
      if (pageIndex > 0) {
        pdf.addPage();
      }

      const isEvenPage = (pageIndex + 1) % 2 === 0;
      const margins = getPageMargins(dimensions, isEvenPage);
      const pageContent = pages[pageIndex];

      for (const block of pageContent) {
        if (block.isImage && block.content) {
          try {
            const img = await loadImage(block.content);
            const aspectRatio = img.width / img.height;

            if (block.layout === 'fullPage') {
              const pageHeight = dimensions.height;
              const targetWidth = pageHeight * aspectRatio;
              const x = (dimensions.width - targetWidth) / 2;

              pdf.addImage(
                block.content,
                'JPEG',
                x,
                0,
                targetWidth,
                pageHeight,
                undefined,
                'FAST'
              );
            } else if (block.layout === 'fullWidth') {
              const contentWidth = dimensions.width - margins.left - margins.right;
              const contentHeight = contentWidth / aspectRatio;

              pdf.addImage(
                block.content,
                'JPEG',
                margins.left,
                margins.top,
                contentWidth,
                contentHeight,
                undefined,
                'FAST'
              );
            } else {
              const maxWidth = dimensions.width - margins.left - margins.right;
              const maxHeight = dimensions.height - margins.top - margins.bottom;
              let targetWidth = img.width / 96;
              let targetHeight = img.height / 96;

              if (targetWidth > maxWidth) {
                const scale = maxWidth / targetWidth;
                targetWidth *= scale;
                targetHeight *= scale;
              }

              if (targetHeight > maxHeight) {
                const scale = maxHeight / targetHeight;
                targetWidth *= scale;
                targetHeight *= scale;
              }

              pdf.addImage(
                block.content,
                'JPEG',
                margins.left,
                margins.top,
                targetWidth,
                targetHeight,
                undefined,
                'FAST'
              );
            }
          } catch (error) {
            console.error('Error processing image:', error);
            continue;
          }
        } else {
          const style = project.styles[block.type];
          pdf.setFont(style.fontFamily || 'helvetica');
          pdf.setFontSize(style.fontSize || 12);
          
          const text = pdf.splitTextToSize(
            block.content,
            dimensions.width - margins.left - margins.right
          );

          pdf.text(text, margins.left, margins.top);
        }
      }
    }

    pdf.save(`${project.title}.pdf`);
  } catch (error) {
    console.error('Erreur lors de la génération du PDF:', error);
    throw error;
  }
}
import { ParsedBlock } from '../types/content';
import { getPageMargins } from './pageMargins';
import { calculatePageDimensions } from './pageCalculations';

interface BlockDimensions {
  height: number;
  marginTop: number;
  marginBottom: number;
}

export function splitContentIntoPages(blocks: ParsedBlock[]): ParsedBlock[][] {
  const pages: ParsedBlock[][] = [];
  let currentPage: ParsedBlock[] = [];
  let currentY = 0;
  
  const dimensions = calculatePageDimensions();
  const margins = getPageMargins(dimensions, false);
  const pageHeight = (dimensions.height - margins.top - margins.bottom) * 96; // Hauteur disponible en pixels

  function getBlockDimensions(block: ParsedBlock): BlockDimensions {
    if (block.isImage) {
      switch (block.layout) {
        case 'fullPage':
          return { height: pageHeight, marginTop: 0, marginBottom: 0 };
        case 'fullWidth':
          return { height: 300, marginTop: 24, marginBottom: 24 };
        default:
          return { height: 200, marginTop: 24, marginBottom: 24 };
      }
    }

    // Calcul de la hauteur pour les blocs de texte
    const lineHeight = block.type.startsWith('heading') ? 48 : 24;
    const fontSize = block.type.startsWith('heading') ? 24 : 16;
    const charsPerLine = Math.floor((dimensions.width - margins.left - margins.right) * 96 / (fontSize * 0.6));
    const lines = Math.ceil(block.content.length / charsPerLine);
    
    return {
      height: lines * lineHeight,
      marginTop: block.type.startsWith('heading') ? 48 : 24,
      marginBottom: block.type.startsWith('heading') ? 36 : 24
    };
  }

  function startNewPage() {
    if (currentPage.length > 0) {
      pages.push([...currentPage]);
      currentPage = [];
      currentY = 0;
    }
  }

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    const dimensions = getBlockDimensions(block);
    const totalHeight = dimensions.height + dimensions.marginTop + dimensions.marginBottom;

    // Forcer une nouvelle page pour les images pleine page et les titres de niveau 1
    if (block.isImage && block.layout === 'fullPage') {
      startNewPage();
      pages.push([block]);
      continue;
    }

    if (block.type === 'heading1' && currentPage.length > 0) {
      startNewPage();
    }

    // Vérifier si le bloc peut tenir sur la page actuelle
    if (currentY + totalHeight > pageHeight) {
      startNewPage();
    }

    // Ajouter le bloc à la page courante
    currentPage.push(block);
    currentY += totalHeight;
  }

  // Ajouter la dernière page si nécessaire
  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
}

export function flattenPages(pages: ParsedBlock[][]): ParsedBlock[] {
  return pages.reduce((acc, page) => [...acc, ...page], []);
}
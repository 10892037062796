import React, { useEffect, useState } from 'react';
import { useBookStore } from '../../store/bookStore';
import { calculatePageDimensions } from '../../utils/pageCalculations';
import { calculateFullPageImageDimensions, calculateFullWidthImageDimensions } from '../../utils/imageHandling';
import { getPageMargins } from '../../utils/pageMargins';

interface ImageDisplayProps {
  src: string;
  alt?: string;
  className?: string;
  layout?: 'normal' | 'fullWidth' | 'fullPage';
  isEvenPage?: boolean;
  style?: React.CSSProperties;
}

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
  src,
  alt = '',
  className = '',
  layout = 'normal',
  isEvenPage = false,
  style = {}
}) => {
  const { currentProject } = useBookStore();
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
  const pageDimensions = calculatePageDimensions(currentProject?.size);
  const margins = getPageMargins(pageDimensions, isEvenPage);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (layout === 'fullPage') {
        const { width, height, x } = calculateFullPageImageDimensions(
          img.width,
          img.height,
          pageDimensions
        );
        setDimensions({ width, height });
        style.transform = `translateX(${x}px)`;
      } else if (layout === 'fullWidth') {
        const { width, height } = calculateFullWidthImageDimensions(
          img.width,
          img.height,
          pageDimensions,
          margins
        );
        setDimensions({ width, height });
      }
    };
    img.src = src;
  }, [src, layout, pageDimensions, margins]);

  if (!dimensions && (layout === 'fullPage' || layout === 'fullWidth')) {
    return null;
  }

  const imageStyle = {
    ...style,
    ...(dimensions ? {
      width: dimensions.width,
      height: dimensions.height
    } : {})
  };

  return (
    <img 
      src={src} 
      alt={alt}
      className={className}
      style={imageStyle}
      onError={(e) => {
        console.error('Erreur de chargement de l\'image:', e);
        e.currentTarget.src = 'https://via.placeholder.com/400x300?text=Image+non+disponible';
      }}
    />
  );
};
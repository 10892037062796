import { BookDimensions } from '../types/dimensions';

interface PageMargins {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export function getPageMargins(dimensions: any, isEvenPage: boolean): PageMargins {
  if (!dimensions?.margins) {
    return {
      top: 0.5,
      bottom: 0.5,
      left: 0.5,
      right: 0.5
    };
  }

  // Utiliser les marges moyennes par défaut
  const margins = dimensions.margins.medium;

  // Pour les pages paires (verso), inverser les marges intérieure et extérieure
  if (isEvenPage) {
    return {
      top: margins.top,
      bottom: margins.bottom,
      left: margins.outside, // Marge extérieure à gauche
      right: margins.inside  // Marge intérieure à droite
    };
  }

  // Pour les pages impaires (recto)
  return {
    top: margins.top,
    bottom: margins.bottom,
    left: margins.inside,   // Marge intérieure à gauche
    right: margins.outside  // Marge extérieure à droite
  };
}